import * as React from 'react'
import Seo from '../../components/seo'
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import Article from '../../components/article'
import { Container } from '@chakra-ui/react'
import Layout from '../../components/layout'
import { Translation } from '../../types/main'
import { translations } from '../../translations'

const t: Translation = {
  language: 'se',
  translations: translations.se
}

const Page = () => (
  <Layout>
    <Seo title="Flowby - Cut your next line" lang="sv" />
    <Navbar t={t} />
    <Container maxW="5xl" py={12}>
      <Article />
    </Container>
    <Container maxW="8xl" py={12}>
      <Footer t={t} />
    </Container>
  </Layout>
)

export default Page
